import * as React from "react";
import { Anchor, Box, Button, Heading, Paragraph } from "grommet";

const DigitalPage = () => {
  return (
    <>
      <Heading level={2} style={{ color: "#3600b2" }}>
        Digital
      </Heading>
      <Box>
        <Paragraph fill>
          <strong style={{color:"#3600b2"}}>Encontrar profissionais</strong> especializados e capacitados
          na área de TI é uma{" "}
          <strong style={{color:"#3600b2"}}>tarefa extremamente complexa e custosa</strong>, quando
          buscamos profissionais com especialização em dados e transformação
          digital (arquitetos de dados, engenheiros de dados, cientistas de
          dados, especialistas em inteligência artificial, especialistas em
          governança de dados, DPOs, desenvolvedores frontend e/ou backend,
          entre outros), essa{" "}
          <strong style={{color:"#3600b2"}}>complexidade aumenta exponencialmente</strong>.
        </Paragraph>

        <Paragraph fill>
          Possuímos um time de consultores especializados em{" "}
          <strong style={{color:"#3600b2"}}>Marketing e Publicidade</strong>, que estão focados em
          resolver as principais <strong style={{color:"#3600b2"}}>dores do seu negócio</strong>, visando{" "}
          <strong style={{color:"#3600b2"}}>crescimento e escala</strong> da sua empresa. Nossa atuação é
          baseada na <strong style={{color:"#3600b2"}}>captura e análise de dados</strong>, através dos
          quais geramos <strong style={{color:"#3600b2"}}>insights</strong> e identificamos{" "}
          <strong style={{color:"#3600b2"}}>tendências do mercado</strong>, e assim conseguimos{" "}
          <strong style={{color:"#3600b2"}}>escalar o seu negócio com velocidade e qualidade</strong>.
        </Paragraph>

        <Paragraph fill>
          <b style={{color: "#3600b2" }}>
            “Não importa o termo, a técnica ou a ferramenta... Fazemos sua
            empresa crescer !!!”
          </b>
        </Paragraph>

        <Paragraph fill>
          <ul>
            {/* GROWTH HACKING */}
            <li style={{ color: "#3600b2" }}>
              <strong id="GrowthHacking" fill style={{ color: "#3600b2" }}>GROWTH HACKING</strong>
            </li>
            <Paragraph fill>
              Nós buscamos o crescimento acelerado da sua empresa, com{" "}
              <strong style={{color:"#3600b2"}}>base em dados</strong>. Na prática, isso significa que são
              realizados diversos <strong style={{color:"#3600b2"}}>experimentos</strong>, construindo um{" "}
              <strong style={{color:"#3600b2"}}>
                ciclo rápido para a evolução exponencial do seu negócio
              </strong>
              .
            </Paragraph>
            <Paragraph style={{ marginBottom: "25px" }} fill>
              Com a nossa <strong style={{color:"#3600b2"}}>metodologia</strong>, podemos{" "}
              <strong style={{color:"#3600b2"}}>identificar as falhas</strong> no seu negócio, a fim de
              repará-las para que você consiga{" "}
              <strong style={{color:"#3600b2"}}>
                captar mais clientes, finalizar suas transações e fidelizá-los
              </strong>
              .
            </Paragraph>
            {/* <Paragraph fill style={{ textAlign: "center" }}>
              <a href="/contact">
                <Button
                  type="submit"
                  label="SAIBA MAIS"
                  primary
                  size="small"
                  style={{ width: "250px" }}
                />
              </a>{" "}
              <a href="/contact">
                <Button
                  type="submit"
                  label="CONTRATAR"
                  primary
                  size="small"
                  style={{ width: "250px" }}
                />
              </a>
            </Paragraph> */}

            {/* CRO */}
            <li style={{ marginTop: "50px" }} style={{ color: "#3600b2" }}>
              <strong id="CRO" fill style={{ color: "#3600b2" }}>CRO</strong>
            </li>
            <Paragraph fill>
              <strong style={{color:"#3600b2"}}>Você sabia que o CRO</strong> (Otimização da Conversão)
              pode ser a chave para <strong style={{color:"#3600b2"}}>transformar seu site</strong> em uma
              poderosa <strong style={{color:"#3600b2"}}>máquina de vendas</strong>?
            </Paragraph>
            <Paragraph style={{ marginBottom: "25px" }} fill>
              Com o CRO, você pode <strong style={{color:"#3600b2"}}>entender</strong> melhor o{" "}
              <strong style={{color:"#3600b2"}}>comportamento de seus usuários</strong> e descobrir como
              transformá-los em clientes fiéis. Além disso, através de testes
              A/B cuidadosamente planejados, você pode melhorar a experiência do
              usuário, aumentar as taxas de conversão e aumentar
              significativamente seus lucros.
            </Paragraph>
            {/* <Paragraph fill style={{ textAlign: "center" }}>
              <a href="/contact">
                <Button
                  type="submit"
                  label="SAIBA MAIS"
                  primary
                  size="small"
                  style={{ width: "250px" }}
                />
              </a>{" "}
              <a href="/contact">
                <Button
                  type="submit"
                  label="CONTRATAR"
                  primary
                  size="small"
                  style={{ width: "250px" }}
                />
              </a>
            </Paragraph> */}

            {/* WEB ANALYTICS */}
            <li style={{ marginTop: "50px" }} style={{ color: "#3600b2" }}>
              <strong id="WEB ANALYTICS" fill style={{ color: "#3600b2" }}>WEB ANALYTICS</strong>
            </li>
            <Paragraph fill>
              Web Analytics é a{" "}
              <strong style={{color:"#3600b2"}}>
                análise de dados digitais capturados por meio de uma ferramenta
              </strong>{" "}
              (por exemplo, o Google Analytics) para a{" "}
              <strong style={{color:"#3600b2"}}>tomada de decisões mais assertivas.</strong>
            </Paragraph>
            <Paragraph fill>
              Não adianta ter <strong style={{color:"#3600b2"}}>boas estratégias</strong> para o seu
              negócio. É preciso{" "}
              <strong style={{color:"#3600b2"}}>mensurar como elas estão sendo aplicadas</strong>, quais
              os <strong style={{color:"#3600b2"}}>efeitos disso pensando em alcançar resultados</strong>.
            </Paragraph>
            <Paragraph style={{ marginBottom: "25px" }} fill>
              Ao adotar a estratégia de <strong>Web Analytics</strong>, você
              pode contar com alguns tipos de análises que te auxiliarão a{" "}
              <strong style={{color:"#3600b2"}}>
                entender como melhorar suas estratégias e gerar mais resultados
                satisfatórios
              </strong>
              .
            </Paragraph>
            {/* <Paragraph fill style={{ textAlign: "center" }}>
              <a href="/contact">
                <Button
                  type="submit"
                  label="SAIBA MAIS"
                  primary
                  size="small"
                  style={{ width: "250px" }}
                />
              </a>{" "}
              <a href="/contact">
                <Button
                  type="submit"
                  label="CONTRATAR"
                  primary
                  size="small"
                  style={{ width: "250px" }}
                />
              </a>
            </Paragraph> */}

            {/* BRANDING */}
            <li style={{ marginTop: "50px" }} style={{ color: "#3600b2" }}>
              <strong id="BRANDING" fill style={{ color: "#3600b2" }}>BRANDING</strong>
            </li>
            <Paragraph fill>
              No mundo competitivo de hoje, o <strong style={{color:"#3600b2"}}>Branding</strong> é a
              chave para se destacar. Crie uma{" "}
              <strong style={{color:"#3600b2"}}>identidade de marca única</strong> que ressoe com seu
              público e deixe uma <strong style={{color:"#3600b2"}}>marca duradoura</strong>.
            </Paragraph>

            {/* MÍDIA PAGA */}
            <li style={{ marginTop: "50px" }} style={{ color: "#3600b2" }}>
              <strong id="MÍDIA PAGA" fill style={{ color: "#3600b2" }}>MÍDIA PAGA</strong>
            </li>
            <Paragraph fill>
              Mas não pare por aí. Com a Mídia Paga, você pode{" "}
              <strong style={{color:"#3600b2"}}>atingir seu público-alvo</strong> exatamente quando
              importa. <strong style={{color:"#3600b2"}}>Direcione anúncios</strong>
              personalizados para <strong style={{color:"#3600b2"}}>maximizar o impacto</strong> e{" "}
              <strong style={{color:"#3600b2"}}>medir o sucesso</strong> em tempo real.
            </Paragraph>

            {/* MARKETING DE CONTEÚDO */}
            <li style={{ marginTop: "50px" }} style={{ color: "#3600b2" }}>
              <strong id="MARKETING DE CONTEÚDO" fill style={{ color: "#3600b2" }}>MARKETING DE CONTEÚDO</strong>
            </li>
            <Paragraph fill>
              O conteúdo é rei, e o <strong style={{color:"#3600b2"}}>Marketing de Conteúdo</strong>{" "}
              reforça sua autoridade. Forneça{" "}
              <strong style={{color:"#3600b2"}}>informações valiosas</strong> por meio de blogs, vídeos e
              redes sociais, e assim{" "}
              <strong style={{color:"#3600b2"}}>conquistando a confiança do seu público</strong>.
            </Paragraph>

            {/* AUTOMAÇÃO DE MARKETING */}
            <li style={{ marginTop: "50px" }} style={{ color: "#3600b2" }}>
              <strong id="AUTOMAÇÃO DE MARKETING" fill style={{ color: "#3600b2" }}>
                AUTOMAÇÃO DE MARKETING
              </strong>
            </li>
            <Paragraph fill>
              A <strong style={{color:"#3600b2"}}>Automação de Marketing</strong> simplifica suas
              operações. <strong style={{color:"#3600b2"}}>Automatize</strong>
              tarefas, <strong style={{color:"#3600b2"}}>segmente</strong> leads com base em comportamento
              e <strong style={{color:"#3600b2"}}>nutra-os</strong>
              automaticamente até a <strong style={{color:"#3600b2"}}>conversão</strong>. Economize tempo
              e recursos enquanto <strong style={{color:"#3600b2"}}>impulsiona o crescimento</strong>.
            </Paragraph>

            {/* INBOUND MARKETING */}
            <li style={{ marginTop: "50px" }} style={{ color: "#3600b2" }}>
              <strong id="INBOUND MARKETING" fill style={{ color: "#3600b2" }}>INBOUND MARKETING</strong>
            </li>
            <Paragraph fill>
              Finalmente, adote o <strong style={{color:"#3600b2"}}>Inbound Marketing</strong> para{" "}
              <strong style={{color:"#3600b2"}}>atrair clientes</strong> de forma natural.{" "}
              <strong style={{color:"#3600b2"}}>Otimize</strong> seu conteúdo para mecanismos de busca,
              <strong style={{color:"#3600b2"}}>forneça informações relevantes</strong> e transforme
              visitantes em
              <strong style={{color:"#3600b2"}}>defensores da marca</strong>.
            </Paragraph>

            <Paragraph fill style={{ textAlign: "center", marginTop: '10%' }}>
              <a href="/contact">
                <Button
                  type="submit"
                  label="SAIBA MAIS"
                  primary
                  size="small"
                  style={{ width: "250px" }}
                />
              </a>{" "}
              <a href="/contact">
                <Button
                  type="submit"
                  label="CONTRATAR"
                  primary
                  size="small"
                  style={{ width: "250px" }}
                />
              </a>
            </Paragraph>
          </ul>
        </Paragraph>
      </Box>
    </>
  );
};

export default DigitalPage;
